import {Content} from "../../../_metronic/layout/components/content";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store.ts";
import {useIntl} from "react-intl";
import searchTalentRequest from "../../core/api/requests/search/searchTalentRequest.ts";
import {useEffect, useState} from "react";
import {clearFilter} from "../../core/store/slices/searcher.ts";
import IAgencyTalent from "../../core/interfaces/IAgencyTalent.ts";
import TalentCard from "../../utils/TalentCard.tsx";
import { KTSVG } from "../../../_metronic/helpers/index.ts";

export default function SearchPage() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const searcherSlice = useSelector((state: RootState) => state.searcher)

  const [ talents, setTalents ] = useState<IAgencyTalent[]>([])

  const [ total, setTotal ] = useState<number>(1)

  const [ currentPage, setCurrentPage ] = useState<number>(1)

  const clear = () => {
    dispatch(clearFilter())
  }

  const searchTalent = async (search: string, page: number) => {
    if (page && page < 1) return
    if (page && page > total) return

    const f = {
      ...searcherSlice.filter,
      query: search,
      page: page || currentPage,
    }

    const response = await searchTalentRequest(
      dispatch,
      f,
    )

    if (response.statusCode !== 200) return

    setTalents(response.data.data)
    setTotal(response.data.pagination.total)
    setCurrentPage(response.data.pagination.page || 1)
    return
  }

  useEffect(() => {
    clear()
    searchTalent('', 1)
  }, [searcherSlice.open]);

  const [ TO, setTO ] = useState<NodeJS.Timeout>()
  useEffect(() => {
    if (TO) {
      clearTimeout(TO)
    }

    setTO(setTimeout(() => {
      searchTalent(searcherSlice.commonSearch, 1)
    }, 500))
  }, [searcherSlice.commonSearch, searcherSlice.filter]);

  return <>
    <Content>
      <div className='row g-5 gx-xxl-8'>
        <div className='col-12'>
          <div className={'card'}>
            <div className='card-body d-flex flex-column'>
              {talents.length > 0 &&
                <div className={'row row-cols-1 row-cols-xl-2'}>
                  {(talents || []).map((talent) => {
                    return <TalentCard
                      key={talent.talent.id}
                      talent={talent.talent}
                    />
                  })}
                </div>
              }

              {talents.length === 0 &&
                <div className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10">
                  <span className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                    <KTSVG path="media/icons/duotune/general/gen004.svg" className="svg-icon-muted svg-icon-2hx" />
                  </span>
                  <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
                    <h4 className="mb-1">{intl.formatMessage({ id: 'ALERTS.NO_TALENTS_FOUND' })}</h4>
                    <span>{intl.formatMessage({ id: 'ALERTS.NO_RESULTS_FOUND_TRY_ANOTHER_SEARCH' })}</span>
                  </div>
                </div>
              }

            </div>
          </div>
        </div>

      </div>
    </Content>
  </>
}
