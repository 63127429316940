import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import IAgencyTalent from "../../interfaces/IAgencyTalent";
import ITalent from "../../interfaces/ITalent";

export interface ISearcher {
  open: boolean;
  filter: any;
  selected: ITalent[];
  commonSearch: string;
}

export interface ITalentSelected {
  talentId: string;
  agencyId: string;
}

const initialState: ISearcher = {
  open: false,
  selected: [],
  commonSearch: '',
  filter: {
    availability: 'active',
    talentStatus: 'inTown',
  }
};

const slice = createSlice({
  name: EStores.SEARCHER,
  initialState,
  reducers: {
    toggleOpenSearcher(state: ISearcher) {
      state.open = !state.open;
    },
    closeSearcher(state: ISearcher) {
      state.open = false;
      return
    },
    setFilterValue(state: ISearcher, action: PayloadAction<{ key: string, value: any }>) {
      state.filter[action.payload.key] = action.payload.value
      return state
    },
    setSelected(state: ISearcher, action: PayloadAction<ITalent[]>) {
      state.selected = action.payload
      return state
    },
    addSelected(state: ISearcher, action: PayloadAction<ITalent>) {
      state.selected.push(action.payload)
      return state
    },
    setCommonSearch(state: ISearcher, action: PayloadAction<string>) { 
      state.commonSearch = action.payload
      return state
    },
    removeSelected(state: ISearcher, action: PayloadAction<ITalent>) {
      state.selected = state.selected.filter(
        (t) => t.id !== action.payload.id,
      )

      return state
    },
    removeSelectedAll(state: ISearcher) {
      state.selected = []
      return state
    },
    clearFilter(state: ISearcher) {
      return {
        ...state,
        filter: initialState.filter,
      }
    }
  },
});

export const {
  toggleOpenSearcher,
  setFilterValue,
  clearFilter,
  setSelected,
  addSelected,
  removeSelected,
  removeSelectedAll,
  closeSearcher,
  setCommonSearch,
} = slice.actions;

export default slice.reducer;
