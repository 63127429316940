import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {SeacherWrapper} from '../pages/searcher/SeacherWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import ERoutes from "../core/enums/ERoutes.ts";

const PrivateRoutes = () => {
  const BookingRouter = lazy(() => import('../modules/booking/BookingPage.tsx'))
  const TalentsRouter = lazy(() => import('../modules/talents/Router.tsx'))
  const PackagesRouter = lazy(() => import('../modules/packages/Router.tsx'))
  const CustomerRouter = lazy(() => import('../modules/customers/Router.tsx'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path={ERoutes.AUTH_ALL} element={<Navigate to={ERoutes.BOOKING} />} />

        {/* Pages */}
        <Route path={ERoutes.DASHBOARD} element={<DashboardWrapper />} />

        <Route path={ERoutes.SEARCHER} element={<SeacherWrapper />} />

        <Route
          path={ERoutes.BOOKING_ALL}
          element={
            <SuspensedView>
              <BookingRouter />
            </SuspensedView>
          } />

        <Route
          path={ERoutes.TALENTS_ALL}
          element={
            <SuspensedView>
              <TalentsRouter />
            </SuspensedView>
          } />

        <Route
          path={ERoutes.PACKAGES_ALL}
          element={
            <SuspensedView>
              <PackagesRouter />
            </SuspensedView>
          } />

        <Route
          path={ERoutes.CUSTOMERS_ALL}
          element={
            <SuspensedView>
              <CustomerRouter />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        {/*<Route path='*' element={<Navigate to='/error/404' />} />*/}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
