enum EModules {
  USERS = 'users',
  ROLES = 'roles',
  PERMISSIONS = 'permissions',
  SESSIONS = 'sessions',
  BOOKINGS = 'bookings',
  TALENTS = 'talents',
  PACKAGES = 'packages',
  CUSTOMERS = 'customers'
}

export default EModules;
