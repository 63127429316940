
import {Route, Routes} from 'react-router-dom'
 import {PackageView} from './components/PackageView'
import { PublicLayout } from './PublicLayout'
import ERoutes from '../../core/enums/ERoutes'

const PublicPage = () => (
  <Routes>
    <Route element={<PublicLayout />}>
      <Route path={ERoutes.PUBLIC_PACKAGES} element={<PackageView />} />
    </Route>
  </Routes>
)

export {PublicPage}
