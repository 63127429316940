import ITalent from "../../../../core/interfaces/ITalent.ts";
import {useIntl} from "react-intl";
import {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../../../../core/store/store.ts";
import getProfilePicture from "../../../../core/utils/getProfilePicture.ts";
import IAgencyTalent from "../../../../core/interfaces/IAgencyTalent.ts";
import { KTSVG } from "../../../../../_metronic/helpers/index.ts";
import {Modal} from 'bootstrap'
import convertToFraction from "../../../../core/utils/convertToFraction.ts";

export interface IComponent {
  show: boolean
  onClose: () => void
  selectedTalent: ITalent | null
}

export default function (props: IComponent) {
  const intl = useIntl()

  const [slides, setSlides] = useState<any[]>([])

  const modalRef = useRef<HTMLDivElement>(null); 
  let modalInstance: Modal | null = null;  

  const createSlides = (files:any[]) => {
    const filesPairs = files.reduce((pairs, file, index) => {
        if (index % 2 === 0) {
          pairs.push([file]);
        } else {
          pairs[pairs.length - 1].push(file);
        }
        return pairs;
      }, []);
      setSlides(filesPairs);
  }

  useEffect(() => {
    if (modalRef.current) {
      modalInstance = new Modal(modalRef.current, {
        backdrop: true,  // Opciones de Bootstrap
        keyboard: true
      });

      // Mostrar el modal si `show` es true
      if (props.show) {
        modalInstance.show();
      } else {
        modalInstance.hide();
      }
    }
    if(props.selectedTalent?.talent?.media?.files){
        createSlides(props.selectedTalent.talent.media.files)
    }
  }, [props.show]);

  const {measurements, characteristics} = props.selectedTalent?.talent?.physical || {}

  return <div
    className={`modal fade`}
    id="kt_modal_add_event"
    aria-hidden="true"
    data-bs-focus="false"
    ref={modalRef}
  >
    <div className="modal-dialog modal-fullscreen">
      <div className="modal-content">
        <div className="modal-header">
            <div className="talent-nav">
                <h1 className="text-capitalize fw-normal">{props.selectedTalent?.name} {props.selectedTalent?.lastName}</h1>
            </div>        
            <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={props.onClose}
            >
                <KTSVG
                    path="media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
            </div>
        </div>
        <div className="modal-body p-0">
            <div id="kt_carousel_talent"
                className="carousel slide carousel-fade"
                data-bs-ride="false"
                data-bs-interval="8000"
            >
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="row align-items-stretch h-100">
                            <div className="col align-self-center">
                                <div className="talent-info text-center">
                                    <h1 className="text-capitalize fw-normal mb-10">{props.selectedTalent?.name} {props.selectedTalent?.lastName}</h1>
                                    <div className="talent-measurements">
                                    {measurements && 
                                    Object.entries(measurements).map(([key, value]) => (
                                        <div className="detail-element" key={key}>
                                            <span className="detail-title text-capitalize">{key}</span>
                                            <span className="detail-value">{value}cm / {convertToFraction(value / 2.54)}</span>
                                        </div> 
                                    ))}
                                    </div>
                                    {/* <div className="talent-measurements">
                                    {characteristics && 
                                    Object.entries(characteristics).map(([key, value]) => (
                                        <div className="measurement-element" key={key}>
                                            <span className="measurement-title text-capitalize">{key}</span>
                                            <span className="measurement-value">{value}</span>
                                        </div> 
                                    ))}
                                    </div> */}
                                    {characteristics && 
                                        <div className="talent-characteristics">
                                            {(characteristics.hairColor || characteristics.hairLength || characteristics.hairType) &&
                                            <div className="detail-element">
                                                <span className="detail-title text-capitalize">Hair</span>
                                                <span className="detail-value text-capitalize">{characteristics.hairType ?? ''} {characteristics.hairLength ?? ''} {characteristics.hairColor ?? '' }</span>
                                            </div> 
                                            }
                                            
                                            {(characteristics.dressSize) &&
                                            <div className="detail-element">
                                                <span className="detail-title text-capitalize">Dress Size</span>
                                                <span className="detail-value text-capitalize">{characteristics.dressSize ?? ''} {characteristics.dressSizeMeasurementUnit ?? ''}</span>
                                            </div> 
                                            }
                                            
                                            {(characteristics.shoesSize) &&
                                            <div className="detail-element">
                                                <span className="detail-title text-capitalize">Shoes Size</span>
                                                <span className="detail-value text-capitalize">{characteristics.shoesSize ?? ''} {characteristics.shoesSizeMeasurementUnit ?? ''}</span>
                                            </div> 
                                            }
                                        </div>
                                    }
                                    <div className="talent-social-media">
                                        {props.selectedTalent?.talent?.socialMedia?.socialMedia && 
                                            <div className="social-media-element my-3">
                                                <a className="btn btn-link btn-color-gray-800 btn-active-color-primary" href={`https://www.instagram.com/${props.selectedTalent?.talent?.socialMedia?.socialMedia[0].userName}`}>
                                                    <span className="social-icon fa-brands fa-instagram fs-2x"></span>
                                                    <span className="social-username ms-2">@{props.selectedTalent?.talent?.socialMedia?.socialMedia[0].userName}</span>
                                                </a>
                                            </div> 
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                {props.selectedTalent &&
                                    <img className={'talent-picture'} src={getProfilePicture(props?.selectedTalent)} />
                                }
                            </div>
                        </div>
                    </div>

                    
                    {slides.length > 0 && slides.map((slideFiles) => 
                        <div className="carousel-item" key={slideFiles[0].id}>
                            <div className="row align-items-stretch h-100 gx-0">
                                <div className="col"> 
                                    <img className={'talent-picture'} src={slideFiles[0].url} />
                                </div>
                                {slideFiles[1] && 
                                    <div className="col">              
                                        <img className={'talent-picture'} src={slideFiles[1].url} />
                                    </div>
                                 }
                            </div>
                        </div>
                    )}
                </div>
                {slides.length > 0 &&
                <>
                    <a className="carousel-control-prev" href="#kt_carousel_talent" role="button" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#kt_carousel_talent" role="button" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </>
                }
            </div>
        </div>
      </div>
    </div>
  </div>
}
