import {useIntl} from "react-intl";
import {IMenuContainer} from "../../../../../app/core/interfaces/IMenuItem.ts";
import ERoutes from "../../../../../app/core/enums/ERoutes.ts";

export default function (): IMenuContainer[] {
  const intl = useIntl()

  return [
    {
      name: intl.formatMessage({id: 'MENU.AGENCY'}),
      soon: false,
      menu: [
        {
          name: intl.formatMessage({id: 'MENU.BOOKING'}),
          icon: 'calendar-8',
          redirectTo: ERoutes.BOOKING,
          soon: false,
          submenu: [
          ]
        },
        {
          name: intl.formatMessage({id: 'MENU.PACKAGES'}),
          icon: 'abstract-26',
          redirectTo: ERoutes.PACKAGES,
          soon: false,
          submenu: []
        },
        {
          name: intl.formatMessage({id: 'MENU.TALENTS'}),
          icon: 'user-square',
          redirectTo: ERoutes.TALENTS,
          soon: false,
          submenu: []
        },
        {
          name: intl.formatMessage({id: 'MENU.COMPANIES'}),
          icon: 'badge',
          redirectTo: ERoutes.CUSTOMERS,
          soon: false,
          submenu: []
        },
        {
          name: intl.formatMessage({id: 'MENU.UPDATE_REQUEST'}),
          icon: 'category',
          redirectTo: '#',
          hide: true,
          soon: true,
          submenu: []
        },
        {
          name: intl.formatMessage({id: 'MENU.USERS'}),
          icon: 'profile-user',
          redirectTo: ERoutes.USERS,
          soon: true,
          hide: true,
          submenu: []
        },
        {
          name: intl.formatMessage({id: 'MENU.ROLES'}),
          icon: 'security-user',
          redirectTo: ERoutes.ROLES,
          hide: true,
          soon: true,
          submenu: []
        },
        {
          name: intl.formatMessage({id: 'MENU.MY_AGENCY'}),
          redirectTo: '#',
          icon: 'chart-line',
          soon: true,
          hide: true,
          submenu: []
        }
      ].sort((a, b) => a.soon === b.soon ? 0 : a.soon ? 1 : -1)
    },
    // {
    //   name: intl.formatMessage({id: 'MENU.TALENT'}),
    //   soon: true,
    //   menu: [
    //     {
    //       name: 'Pages',
    //       icon: 'element-plus',
    //       redirectTo: '/crafted/pages',
    //       fontIcon: 'bi-archive',
    //       soon: false,
    //       submenu: [
    //         {
    //           name: 'Profile',
    //           redirectTo: '/crafted/pages/profile',
    //           submenu: [
    //             {
    //               name: 'Overview',
    //               redirectTo: '/crafted/pages/profile/overview',
    //             },
    //             {
    //               name: 'Project',
    //               redirectTo: '/crafted/pages/profile/projects',
    //             },
    //             {
    //               name: 'Campaigns',
    //               redirectTo: '/crafted/pages/profile/campaigns',
    //             },
    //             {
    //               name: 'Documents',
    //               redirectTo: '/crafted/pages/profile/documents',
    //             },
    //             {
    //               name: 'Connections',
    //               redirectTo: '/crafted/pages/profile/connections',
    //             }
    //           ]
    //         }
    //       ]
    //     },
    //   ],
    // },
    // {
    //   name: intl.formatMessage({id: 'MENU.DIRECTOR'}),
    //   soon: true,
    //   menu: []
    // },
    // {
    //   name: intl.formatMessage({id: 'MENU.SUPER_ADMIN'}),
    //   soon: true,
    //   menu: []
    // }
  ]
}
