import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import SearchPage from "./SearchPage.tsx";

const SeacherWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SEARCHER'})}</PageTitle>

      <SearchPage />
    </>
  )
}

export {SeacherWrapper}
