import ITalent from "../interfaces/ITalent";
import getPictureOrAvatar from "./getPictureOrAvatar";

const BASE = `https://ui-avatars.com/api/?background=666&color=fff&name={{NAME}}`.replace(' ', '+')

const BASE_RANDOM = `https://ui-avatars.com/api/?background=random&name={{NAME}}`.replace(' ', '+')

export default function (
  talent?: ITalent,
  randomColor: boolean = false,
): string {
  const name = talent?.name?.trim() || 'Unknown'
  const lastName = talent?.lastName?.trim() || ''

  return getPictureOrAvatar(
    `${name} ${lastName}`,
    talent?.profileImage || null,
    randomColor,
  )
}
