import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {useThemeMode} from '../../../_metronic/partials'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { PageDataProvider } from '../../../_metronic/layout/core'

const PublicLayout = () => {

  return (
    <PageDataProvider>
        <div className='d-flex flex-column flex-root app-root vh-100' id='kt_app_root'>
            <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
                <Outlet />
            </div>
        </div>
    </PageDataProvider>
  )
}

export {PublicLayout}
