enum EFilterOptionType {
  SELECT_ONE = 'selectOne',
  SELECT_MANY = 'selectMany',
  TEXT = 'text',
  DATE = 'date',
  TIME = 'time',
  BOOLEAN = 'boolean',
  SWITCH = 'switch',
  NUMBER_RANGE = 'numberRange',
  TEXT_RANGE = 'textRange',
}

export default EFilterOptionType;
