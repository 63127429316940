function gcd(a: number, b: number): number {
  if (b < 0.0000001) return a;

  return gcd(b, Math.floor(a % b));
};

export default function (inch: number): string {
  const feet = Math.floor(inch / 12)

  const inches = inch - (feet * 12);

  const floor = Math.floor(inches)
  const rest = inches - floor

  let restToShow = ""
  if (rest >= 0.5 && rest <= 0.6) {
    restToShow = "½"
  }

  return `${feet}' ${floor}${restToShow}"`
}
