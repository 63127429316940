import MultiRangeSlideReact from 'multi-range-slider-react/lib/multirangeslider';
import {useEffect, useState} from "react";

export interface IComponent {
  min: number;
  max: number;
  onChange(min: number, max: number): void;
  onInput?(min: number, max: number): void;
  minValue: number;
  maxValue: number;
  unity: string;
  step?: number;
  secondaryUnity?(value: number): string
  single?: boolean;
}

export default function (props: IComponent) {
  const [ minLabel, setMinLabel ] = useState<string>('')
  const [ maxLabel, setMaxLabel ] = useState<string>('')

  const  [ secondaryMin, setSecondaryMin ] = useState<string>('')
  const  [ secondaryMax, setSecondaryMax ] = useState<string>('')

  const onChangeInput = (min: number, max: number) => {
    setMinLabel(min.toString())
    setMaxLabel(max.toString())
  }

  useEffect(() => {
    if (props.secondaryUnity) {
      setSecondaryMin(props.secondaryUnity(props.minValue))
      setSecondaryMax(props.secondaryUnity(props.maxValue))
    }

    setMinLabel(props.minValue.toString())
    setMaxLabel(props.maxValue.toString())
  }, [props.minValue, props.minValue]);

  return <div className="row">
    <div className={'col-12 d-flex justify-content-between'}>
      <p>{minLabel} {props.unity} {secondaryMin ? `/ ${secondaryMin}` : ''}</p>
      <p>{maxLabel} {props.unity} {secondaryMax ? `/ ${secondaryMax}` : ''}</p>
    </div>

    <MultiRangeSlideReact
      min={props.min || 0}
      max={props.max || 100}
      minValue={props.minValue || 0}
      maxValue={props.maxValue || 100}
      step={props.step || 1}
      stepOnly={false}
      canMinMaxValueSame={true}
      preventWheel={true}
      disabled={false}
      ruler={false}
      label={false}
      onChange={(cr) => props.onChange(cr.minValue, cr.maxValue)}
      onInput={(cr) => onChangeInput(cr.minValue, cr.maxValue)}
      minCaption={minLabel}
      maxCaption={maxLabel}
      subSteps={false}
      style={{
        borderRadius: 'unset',
        boxShadow: 'none',
        paddingTop: '6px',
        paddingBottom: '0',
        paddingLeft: 15,
        paddingRight: 15,
        border: 'none',
      }}
      barLeftColor={'#C5C5C5'}
      barRightColor={'#C5C5C5'}
      barInnerColor={'#000'}
      thumbLeftColor={'#000'}
      thumbRightColor={'#000'}
    />
  </div>
}
