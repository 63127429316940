import {createSlice, PayloadAction, UnknownAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import IPackage from "../../interfaces/IPackage.ts";
import ICustomer from "../../interfaces/ICustomer.ts";
import IPageData from "../../interfaces/IPageData.ts";

type IStore = {
  customer?: ICustomer
  completed?: string;
  save?: boolean
  pageData: {
    [key: string]: IPageData
  }
}

const initialState: IStore = {
  completed: '0',
  save: false,
  pageData: {}
};

const store = createSlice({
  name: EStores.PACKAGE,
  initialState,
  reducers: {
    setCustomer(state: IStore, data: PayloadAction<ICustomer>) {
      state.customer = data.payload;

      if (state.customer) {
        const values: boolean[] = [
        ]

        const cmp = values.filter((value) => value).length
        if (!values.length) {
          state.completed = '0'
          return;
        }

        state.completed = (cmp / values.length * 100).toFixed(1)
      }

      return
    },
    clearCustomer(state: IStore) {
      state.customer = undefined;
      state.completed = '0';
      state.save = false;
      state.pageData = {};
      return
    },
    setCustomerValues(state: IStore, data: PayloadAction<IPageData>) {
      state.pageData[data.payload.key] = data.payload;
      return
    },
    saveCustomer(state: IStore) {
      state.save = true
      return
    },
    setSaveCustomerFalse(state: IStore) {
      state.save = false
      return
    }
  },
});

export const {
  setCustomer,
  clearCustomer,
  setCustomerValues,
  saveCustomer,
  setSaveCustomerFalse
} = store.actions;

export default store.reducer;
