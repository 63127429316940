import {FC, useRef} from 'react'
import {KTIcon} from '../../../helpers'
import {useNavigate} from "react-router-dom";
import ERoutes from "../../../../app/core/enums/ERoutes.ts";

const Search: FC = () => {
  const element = useRef<HTMLDivElement | null>(null)

  const navigator = useNavigate()

  const redirectToSearch = () => navigator(ERoutes.SEARCHER)

  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div
            onClick={redirectToSearch}
            className='btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
          >
            <KTIcon iconName='magnifier' className='fs-2'/>
          </div>
        </div>

      </div>
    </>
  )
}

export {Search}
