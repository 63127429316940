import {toAbsoluteUrl} from "../../_metronic/helpers";

export interface IComponent {
  alt?: string;
  src?: string;
  small?: boolean;
}

export default function (props: IComponent) {
  if (props.small) {
    return <>
      <img
        alt='Logo'
        src={toAbsoluteUrl('media/logos/default-small.svg')}
        className='h-20px app-sidebar-logo-minimize theme-light-show'
      />
      <img
        alt='Logo'
        src={toAbsoluteUrl('media/logos/default-small-dark.svg')}
        className='h-20px app-sidebar-logo-minimize theme-dark-show'
      />
    </>
  }

  return <>
    <img
      alt='Logo'
      src={toAbsoluteUrl('media/logos/default.svg')}
      className='h-25px app-sidebar-logo-default theme-light-show'
    />

    <img
      alt='Logo'
      src={toAbsoluteUrl('media/logos/default-dark.svg')}
      className='h-25px app-sidebar-logo-default theme-dark-show'
    />
  </>
}
