import {FC} from 'react'
import {useIntl} from "react-intl";
import ERoutes from "../../../../app/core/enums/ERoutes.ts";

const ToggleSearcherButton: FC = () => {
  const intl = useIntl()

  if (!window.location.pathname.includes(ERoutes.SEARCHER) && !window.location.hash.includes('showSearcher')) return null

  return <button
    id='kt_help_toggle'
    className='engage-help-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm px-5 rounded-top-0'
  >
    {intl.formatMessage({ id: 'MENU.SEARCHER' })}
  </button>
}

export {ToggleSearcherButton}
