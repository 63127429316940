import clsx from "clsx";
import {useIntl} from "react-intl";

export interface IComponent {
  hideLabel?: boolean;
  fieldsProps: any;
  i18n: string;
  error?: string;
  value?: string;
  touched?: boolean;
  disabled?: boolean;
  className?: string;
  rows?: number;
}

export default function (props: IComponent) {
  const intl = useIntl()

  return <>
    {!props.hideLabel &&
      <label className="fs-6 fw-semibold mb-2">{intl.formatMessage({id: props.i18n})}</label>
    }

    <textarea
      placeholder={intl.formatMessage({id: props.i18n})}
      autoComplete='off'
      {...props.fieldsProps}
      className={props.className || 'form-control form-control-solid'}
      rows={props.rows || 3}
      disabled={props.disabled}
    >
    </textarea>

    {(props.touched && props.error) && (
      <div className='fv-plugins-message-container'>
        <div className='fv-help-block'>
          <span role='alert'>{props.error}</span>
        </div>
      </div>
    )}
  </>
}
