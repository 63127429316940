import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";
import {GetSelectedCompany} from "../../../../utils/selectCompany";

export default async function (
  dispatch: Dispatch,
  id: string,
  companyId?: string
): Promise<IResponse> {
  dispatch(startLoading());

  const cid = companyId ? companyId : GetSelectedCompany();
  if (!cid) {
    return {
      statusCode: 500,
      data: {
        message: 'No company selected'
      }
    }
  }

  const request = new RACARequest('companyPackages', 'view')
    .setPathValues('COMPANY_ID', cid)
    .setPathValues('ID', id)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
