import IFilterSection from "../interfaces/IFilterSection";
import {Dispatch} from "redux";
import getFrontendUtilitiesRequest from "../api/requests/utils/getFrontUtilitiesRequest";
import EFilterOptionType from "../enums/EFilterOptionType";
import convertToFraction from "./convertToFraction";

export default async function(
  dispatch: Dispatch,
): Promise<IFilterSection[]> {
  const futils = await getFrontendUtilitiesRequest(dispatch);

  return [
    {
      name: 'Información',
      fields: [
        {
          name: 'Disponibilidad',
          field: 'availability',
          type: EFilterOptionType.SELECT_ONE,
          values: futils.data.talents.agencyInternal?.availability,
        },
        {
          name: 'Estado',
          field: 'talentStatus',
          type: EFilterOptionType.SWITCH,
          values: futils.data.talents.personalInformation.talentStatus,
        },
        {
          name: '¿Ha trabajado?',
          field: 'hasWorked',
          type: EFilterOptionType.SELECT_ONE,
          values: futils.data.talents.agencyInternal?.workHistory,
        },
        {
          name: 'Tipo de modelo',
          field: 'modelType',
          type: EFilterOptionType.SELECT_MANY,
          values: futils.data.talents.experience.modelTypes,
        },
        {
          name: 'Categoría de actor',
          field: 'actorCategory',
          type: EFilterOptionType.SELECT_MANY,
          values: futils.data.talents.experience.categories,
          show(options: any): boolean {
            if (!options.modelType) return false
            if (options.modelType.length === 0) return false

            return options.modelType.includes('Fiction')
          }
        },
        {
          name: 'Acting con beso',
          field: 'kissActing',
          type: EFilterOptionType.SELECT_ONE,
          values: futils.data.talents.specialInformation.kissActress,
          show(options: any): boolean {
            if (!options.modelType) return false
            if (options.modelType.length === 0) return false

            return options.modelType.includes('Fiction')
          }
        },
        {
          name: 'Género',
          field: 'gender',
          type: EFilterOptionType.SELECT_ONE,
          values: futils.data.talents.personalInformation.gender,
        },
        {
          name: 'Edad',
          field: 'age',
          type: EFilterOptionType.NUMBER_RANGE,
          min: 10,
          max: 100,
          defaultNumber: 18,
          defaultNumberMax: 30,
          unity: 'años',
        },
        {
          name: 'Nacionalidad',
          field: 'nationality',
          type: EFilterOptionType.SELECT_MANY,
          values: futils.data.talents.personalInformation.nationalities,
        },
        {
          name: 'Etnia',
          field: 'ethnicity',
          type: EFilterOptionType.SELECT_MANY,
          values: futils.data.talents.personalInformation.ethnicity,
        },
        {
          name: 'Profesión',
          field: 'profession',
          type: EFilterOptionType.SELECT_MANY,
          values: futils.data.talents.personalInformation.professions,
        },
        {
          name: 'Ciudades de residencia',
          field: 'residenceCity',
          type: EFilterOptionType.SELECT_MANY,
          values: futils.data.talents.contact.residenceCity,
        },
        {
          name: 'País de origen',
          field: 'originCountry',
          type: EFilterOptionType.SELECT_ONE,
          values: futils.data.talents.others.residenceCountry,
        },
      ]
    },
    {
      name: 'Características',
      groups: [
        {
          name: 'Medidas',
          fields: [
            {
              name: 'Altura',
              field: 'height',
              type: EFilterOptionType.NUMBER_RANGE,
              min: 120,
              max: 215,
              defaultNumber: 160,
              defaultNumberMax: 180,
              secondaryUnity(value: number): string {
                return convertToFraction(value / 2.54)
              },
              unity: 'cm',
            },
            {
              name: 'Pecho',
              field: 'chest',
              type: EFilterOptionType.NUMBER_RANGE,
              min: 65,
              max: 120,
              defaultNumber: 85,
              defaultNumberMax: 100,
              unity: 'cm',
              secondaryUnity(value: number): string {
                return convertToFraction(value / 2.54)
              },
            },
            {
              name: 'Cintura',
              field: 'waist',
              type: EFilterOptionType.NUMBER_RANGE,
              min: 50,
              max: 100,
              defaultNumber: 65,
              defaultNumberMax: 80,
              unity: 'cm',
              secondaryUnity(value: number): string {
                return convertToFraction(value / 2.54)
              },
            },
            {
              name: 'Cadera',
              field: 'hip',
              type: EFilterOptionType.NUMBER_RANGE,
              min: 65,
              max: 120,
              defaultNumber: 90,
              defaultNumberMax: 110,
              unity: 'cm',
              secondaryUnity(value: number): string {
                return convertToFraction(value / 2.54)
              },
            },
          ]
        },
        {
          name: 'Tallas',
          fields: [
            {
              name: 'Camisa',
              field: 'shirtSize',
              type: EFilterOptionType.NUMBER_RANGE,
              min: 32,
              max: 60,
              defaultNumber: 38,
              defaultNumberMax: 50,
              unity: 'EU',
            },
            {
              name: 'Pantalón',
              field: 'pantsSize',
              type: EFilterOptionType.NUMBER_RANGE,
              min: 32,
              max: 60,
              defaultNumber: 38,
              defaultNumberMax: 50,
              unity: 'EU',
            },
            {
              name: 'Calzado',
              field: 'shoeSize',
              type: EFilterOptionType.NUMBER_RANGE,
              min: 35,
              max: 55,
              defaultNumber: 40,
              defaultNumberMax: 50,
              unity: 'EU',
            },
            {
              name: 'Copa sujetador',
              field: 'braCup',
              type: EFilterOptionType.TEXT_RANGE,
              values: futils.data.talents.physicalCharacteristics.cup,
            },
          ],
        },
        {
          name: 'Pelo',
          fields: [
            {
              name: 'Color',
              field: 'hairColor',
              type: EFilterOptionType.SELECT_ONE,
              values: futils.data.talents.physicalCharacteristics.hairColor,
            },
            {
              name: 'Tipo',
              field: 'hairType',
              type: EFilterOptionType.SELECT_ONE,
              values: futils.data.talents.physicalCharacteristics.hairType,
            },
            {
              name: 'Longitud',
              field: 'hairLength',
              type: EFilterOptionType.SELECT_ONE,
              values: futils.data.talents.physicalCharacteristics.hairLength,
            },
            {
              name: 'Mechas',
              field: 'hairWicks',
              type: EFilterOptionType.BOOLEAN,
            },
            {
              name: '¿Cortar?',
              field: 'allowCut',
              type: EFilterOptionType.SELECT_ONE,
              values: futils.data.talents.physicalCharacteristics.hairCutAllow,
            },
            {
              name: '¿Teñir?',
              field: 'allowChangeColor',
              type: EFilterOptionType.BOOLEAN,
            },
            {
              name: 'Facial',
              field: 'facialHair',
              type: EFilterOptionType.SELECT_ONE,
              values: futils.data.talents.physicalCharacteristics.hairFacialType,
            },
          ],
        },
        {
          name: 'Ojos',
          fields: [
            {
              name: 'Color',
              field: 'eyesColor',
              type: EFilterOptionType.SELECT_ONE,
              values: futils.data.talents.physicalCharacteristics.eyesColor,
            },
            {
              name: 'Gafas',
              field: 'glasses',
              type: EFilterOptionType.BOOLEAN,
            },
            {
              name: 'Lentillas',
              field: 'contactLenses',
              type: EFilterOptionType.BOOLEAN,
            },
          ],
        }
      ],
      fields: [],
    },
    {
      name: 'Aspecto',
      fields: [
        {
          name: 'Hermano Gemelo',
          field: 'twinBrother',
          type: EFilterOptionType.BOOLEAN,
        },
        {
          name: 'Tipo de cuerpo',
          field: 'bodyType',
          type: EFilterOptionType.SELECT_ONE,
          values: futils.data.talents.physicalCharacteristics.bodyType,
        },
        {
          name: 'Tipo de piel',
          field: 'skinType',
          type: EFilterOptionType.SELECT_ONE,
          values: futils.data.talents.physicalCharacteristics.skinType,
        },
        {
          name: 'Manos',
          field: 'handType',
          type: EFilterOptionType.SELECT_ONE,
          values: futils.data.talents.distinctiveCharacteristics.hands,
        },
        {
          name: 'Pies',
          field: 'footsType',
          type: EFilterOptionType.SELECT_ONE,
          values: futils.data.talents.distinctiveCharacteristics.foots,
        },
        {
          name: 'Boca',
          field: 'mouthType',
          type: EFilterOptionType.SELECT_ONE,
          values: futils.data.talents.distinctiveCharacteristics.mouth,
        },
        {
          name: 'Pecho operado',
          field: 'operatedChest',
          type: EFilterOptionType.BOOLEAN,
        },
        {
          name: 'Cicatriz',
          field: 'scars',
          type: EFilterOptionType.BOOLEAN,
        },
        {
          name: 'Tatuaje',
          field: 'tattoos',
          type: EFilterOptionType.BOOLEAN,
        },
        {
          name: 'Piercings',
          field: 'piercings',
          type: EFilterOptionType.BOOLEAN,
        },
        {
          name: 'Agujeros oreja I',
          field: 'leftEarHole',
          type: EFilterOptionType.NUMBER_RANGE,
          min: 0,
          max: 6,
          defaultNumber: 0,
          defaultNumberMax: 0,
        },
        {
          name: 'Agujeros oreja D',
          field: 'rightEarHole',
          type: EFilterOptionType.NUMBER_RANGE,
          min: 0,
          max: 6,
          defaultNumber: 0,
          defaultNumberMax: 0,
        },
      ]
    },
    {
      name: 'Habilidades',
      fields: [
        {
          name: 'Idiomas',
          field: 'languages',
          type: EFilterOptionType.SELECT_MANY,
          values: futils.data.talents.language.languages,
        },
        {
          name: 'Acentos',
          field: 'dialects',
          type: EFilterOptionType.SELECT_MANY,
          values: futils.data.talents.language.dialects,
        },
        {
          name: 'Lenguaje de signos',
          field: 'signLanguage',
          type: EFilterOptionType.BOOLEAN,
        },
        {
          name: 'Carné de conducir',
          field: 'driverLicence',
          type: EFilterOptionType.SELECT_MANY,
          values: futils.data.talents.skills.driverLicence,
        },
        {
          name: 'Deportes',
          field: 'sports',
          type: EFilterOptionType.SELECT_MANY,
          values: futils.data.talents.skills.sports,
        },
        {
          name: 'Cantas',
          field: 'isSinger',
          type: EFilterOptionType.BOOLEAN,
        },
        {
          name: 'Bailas',
          field: 'dancer',
          type: EFilterOptionType.SELECT_MANY,
          values: futils.data.talents.skills.dancing,
        },
        {
          name: 'Instrumentos',
          field: 'instruments',
          type: EFilterOptionType.SELECT_MANY,
          values: futils.data.talents.skills.instruments,
        },
        {
          name: 'Discapacidad',
          field: 'disability',
          type: EFilterOptionType.SELECT_MANY,
          values: futils.data.talents.specialInformation.disabilities,
        },
        {
          name: 'Prótesis en extremidades',
          field: 'limbProsthesis',
          type: EFilterOptionType.BOOLEAN,
        },
        {
          name: 'Fumador',
          field: 'smoker',
          type: EFilterOptionType.SELECT_ONE,
          values: futils.data.talents.specialInformation.smoker,
        },
        {
          name: '¿Diestro o zurdo?',
          field: 'rightOrLeftHanded',
          type: EFilterOptionType.SELECT_ONE,
          values: futils.data.talents.specialInformation.handedness,
        },
        {
          name: 'Embarazada',
          field: 'pregnant',
          type: EFilterOptionType.BOOLEAN,
        },
        {
          name: 'Fecha de parto',
          field: 'pregnantDate',
          type: EFilterOptionType.DATE,
          show(options: any): boolean {
            return options.pregnant === true
          }
        },
        {
          name: 'Mascotas',
          field: 'pets',
          type: EFilterOptionType.SELECT_MANY,
          values: futils.data.talents.specialInformation.pets,
        },
      ],
    }
  ]
}
