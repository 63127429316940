/**
 * Convierte una fecha de nacimiento en edad en años
 * @param birthDate Fecha de nacimiento en formato Date, string o timestamp
 * @returns Edad en años como número
 */
export default function calculateAge(birthDate: Date | string | number): number {
    const birthDateObj = new Date(birthDate); // Convierte a Date si es necesario
  
    if (isNaN(birthDateObj.getTime())) {
      throw new Error("Fecha de nacimiento inválida.");
    }
  
    const today = new Date();
    let age = today.getFullYear() - birthDateObj.getFullYear();
  
    // Ajuste en caso de que el cumpleaños aún no haya ocurrido este año
    const monthDifference = today.getMonth() - birthDateObj.getMonth();
    const dayDifference = today.getDate() - birthDateObj.getDate();
    
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }
  
    return age;
  }
  