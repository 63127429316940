import { FC } from "react";
import { SidebarLogo } from "../../../../../_metronic/layout/components/sidebar/SidebarLogo";
import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../core/store/store";
import { Messages } from "../../../packages/components/Messages";
import { useIntl } from "react-intl";
import downloadCompanyPackageRequest from "../../../../core/api/requests/companies/package/downloadCompanyPackageRequest";
import { useParams } from "react-router-dom";

const PackageSidebar: FC = () => {
    const sidebarRef = useRef<HTMLDivElement>(null)
    const intl = useIntl()
    const dispatch = useDispatch()
    const params = useParams()
    const [likeCount, setLikeCount] = useState<number>(0)
    const [maybeCount, setMaybeCount] = useState<number>(0)

    const pkg = useSelector((state: RootState) => state.package)

    useEffect(() => {        
        setLikeCount(pkg?.package?.talents?.filter(talent => talent.status === 'like').length || 0);
        setMaybeCount(pkg?.package?.talents?.filter(talent => talent.status === 'maybe').length || 0);
    },[pkg])

    const downloadFile = async () => {
        if (!params.id || !params.cid) return

        const url = import.meta.env.VITE_APP_API_URL
        const id = params.id
        const companyId = params.cid
        const pdfUrl = `${url}/p/company/${companyId}/packages/${id}/pdf?talents=all`
        
        // const likedTalents = pkg?.package?.talents?.filter(talent => talent.status === 'like')
        // .map(talent => talent.user.id );
        // const talents = likedTalents && likedTalents.length > 0 ? likedTalents.join(',') : 'all';

        // const pdfUrl = `${url}/p/company/${companyId}/packages/${id}/pdf?talents=${talents}`

        window.open(pdfUrl, '_blank');
    }

    return(
        <div className="package-sidebar app-sidebar flex-column justify-content-between h-100 w-lg-325px position-fixed">            
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                <SidebarLogo sidebarRef={sidebarRef} />
            </div>
            {pkg && 
                <>
                    <div className="package-info text-center border-bottom p-4">
                        <div className="package-name py-2">
                            <span className="title">{pkg?.package?.name}</span>
                        </div>
                        <div className="package-selection py-2">
                            <p>{intl.formatMessage({id: 'PLACEHOLDERS.MY_SELECTION'})}: <span className="like">{likeCount} {intl.formatMessage({id: 'PLACEHOLDERS.LIKE'})}</span> - <span className="unlike">{maybeCount} {intl.formatMessage({id: 'PLACEHOLDERS.MAYBE'})}</span></p>
                            <button className="btn btn-outline selection" onClick={() => downloadFile()}>
                                <i className="las la-download fs-1"></i>
                                {intl.formatMessage({id: 'PLACEHOLDERS.DOWNLOAD_PACKAGE'})}
                            </button>
                        </div>
                    </div>
                    <div className="package-messages flex-grow-1">
                        <Messages />
                    </div>
                </>
            }

        </div>
    )
    
}

export {PackageSidebar}