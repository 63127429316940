import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import IFrontendUtilities from "../../interfaces/IFrontendUtilities";
import IFilterSection from "../../interfaces/IFilterSection";

type SliceType = {
  futils?: any;
  filter?: IFilterSection[];
}

const initialState: SliceType = {
  filter: [],
};

const slice = createSlice({
  name: EStores.FRONTEND_UTILITIES,
  initialState,
  reducers: {
    setFilters(state: SliceType, data: PayloadAction<IFilterSection[]>) {
      return {
        ...state,
        filter: data.payload,
      }
    },
    setFU(state: SliceType, data: PayloadAction<IFrontendUtilities>) {
      return {
        ...state,
        futils: data.payload,
      }
    },
  },
});

export const {
  setFU,
  setFilters,
} = slice.actions;

export default slice.reducer;
