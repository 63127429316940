
import clsx from 'clsx'
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {
  defaultAlerts,
  defaultLogs,
  KTIcon,
  toAbsoluteUrl,
  useIllustrationsPath,
} from '../../../helpers'
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/core/store/store.ts";
import {useIntl} from "react-intl";

const HeaderNotificationsMenu: FC = () => {
  const intl = useIntl()
  const notifications = useSelector((state: RootState) => state.notifications)

  return <div
    className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
    data-kt-menu='true'
  >
    <div
      className='d-flex flex-column bgi-no-repeat rounded-top'
      style={{backgroundImage: `url('${toAbsoluteUrl('media/misc/menu-header-bg.jpg')}')`}}
    >
      <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
        {intl.formatMessage({ id: 'NOTIFICATIONS.NOTIFICATIONS' })} <span className='fs-8 opacity-75 ps-3'>{notifications.notifications.length} {intl.formatMessage({ id: 'NOTIFICATIONS.REPORTS' })}</span>
      </h3>

      <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
        <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4'
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_1'
          >
            {intl.formatMessage({ id: 'NOTIFICATIONS.ALERT' })}
          </a>
        </li>

        {/*<li className='nav-item'>*/}
        {/*  <a*/}
        {/*    className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'*/}
        {/*    data-bs-toggle='tab'*/}
        {/*    href='#kt_topbar_notifications_2'*/}
        {/*  >*/}
        {/*    Updates*/}
        {/*  </a>*/}
        {/*</li>*/}

        {/*<li className='nav-item'>*/}
        {/*  <a*/}
        {/*    className='nav-link text-white opacity-75 opacity-state-100 pb-4'*/}
        {/*    data-bs-toggle='tab'*/}
        {/*    href='#kt_topbar_notifications_3'*/}
        {/*  >*/}
        {/*    Logs*/}
        {/*  </a>*/}
        {/*</li>*/}
      </ul>
    </div>

    <div className='tab-content'>
      <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
        <div className='scroll-y mh-325px my-5 px-8'>
          {defaultAlerts.map((alert, index) => (
            <div key={`alert${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-4'>
                  <span className={clsx('symbol-label', `bg-light-${alert.state}`)}>
                    {' '}
                    <KTIcon iconName={alert.icon} className={`fs-2 text-${alert.state}`} />
                  </span>
                </div>

                <div className='mb-0 me-2'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                    Angel modifico un evento
                  </a>
                  <div className='text-gray-500 fs-7'>Angel ha modificado un evento</div>
                </div>
              </div>

              <span className='badge badge-light fs-8'>{alert.time}</span>
            </div>
          ))}
        </div>

        <div className='py-3 text-center border-top'>
          <Link
            to='/crafted/pages/profile'
            className='btn btn-color-gray-600 btn-active-color-primary'
          >
            {intl.formatMessage({ id: 'COMMON.VIEW_ALL' })} <KTIcon iconName='arrow-right' className='fs-5' />
          </Link>
        </div>
      </div>
    </div>
  </div>
}

export {HeaderNotificationsMenu}
