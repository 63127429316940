import MultiRangeSlideReact from 'multi-range-slider-react/lib/multirangeslider';
import {useEffect, useState} from "react";

export interface IComponent {
  steps: string[];
  minValue: string;
  maxValue: string;
  onChange(stepMin: string, stepMax: string): void;
  onInput?(step: string): void;
}



export default function (props: IComponent) {
  const [ minLabel, setMinLabel ] = useState<string>('')
  const [ maxLabel, setMaxLabel ] = useState<string>('')

  const onInput = (minIndex: number, maxIndex: number) => {
    const indexMin = props.steps[minIndex]
    if (indexMin) {
      setMinLabel(indexMin)
    }

    const indexMax = props.steps[maxIndex]
    if (indexMax) {
      setMaxLabel(indexMax)
    }
  }

  const onChangeInput = (minIndex: number, maxIndex: number) => {
    const indexMin = props.steps[minIndex]
    if (indexMin) {
      setMinLabel(indexMin)
    }

    const indexMax = props.steps[maxIndex]
    if (indexMax) {
      setMaxLabel(indexMax)
    }

    if (!indexMin || !indexMax) {
      return
    }

    if (props.onChange) {
      props.onChange(indexMin, indexMax)
    }
  }
  useEffect(() => {
    const indexMin = props.steps.indexOf(props.minValue)
    if (indexMin !== -1) {
      setMinLabel(props.steps[indexMin])
    }

    const indexMax = props.steps.indexOf(props.maxValue)
    if (indexMax !== -1) {
      setMaxLabel(props.steps[indexMax])
    }
  }, [props.minValue, props.minValue]);

  return <div className="row">
    <div className={'col-12 d-flex justify-content-between'}>
      <p>{minLabel || props.steps[0]}</p>
      <p>{maxLabel || props.steps[props.steps.length - 1]}</p>
    </div>

    <MultiRangeSlideReact
      min={0}
      max={props.steps.length - 1}
      minValue={props.minValue}
      maxValue={props.maxValue || props.steps.length - 1}
      step={1}
      stepOnly={false}
      canMinMaxValueSame={true}
      preventWheel={true}
      disabled={false}
      ruler={false}
      label={false}
      onInput={(cr) => onInput(cr.minValue, cr.maxValue)}
      onChange={(cr) => onChangeInput(cr.minValue, cr.maxValue)}
      minCaption={minLabel}
      maxCaption={maxLabel}
      style={{
        borderRadius: 'unset',
        boxShadow: 'none',
        paddingTop: '6px',
        paddingBottom: '0',
        paddingLeft: 15,
        paddingRight: 15,
        border: 'none',
      }}
      barLeftColor={'#C5C5C5'}
      barRightColor={'#C5C5C5'}
      barInnerColor={'#000'}
      thumbLeftColor={'#000'}
      thumbRightColor={'#000'}

    />
  </div>
}
