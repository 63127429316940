import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";
import {GetSelectedCompany} from "../../../../utils/selectCompany";

export default async function (
  dispatch: Dispatch,
  id: string,
  companyId: any,
  message: string,
  from?:string,
): Promise<IResponse> {
  
  if (!companyId) {
    return {
      statusCode: 500,
      data: {
        message: 'No company selected'
      } 
    }
  }
  dispatch(startLoading());

  const request = new RACARequest('companyPackages', 'sendMessage')
    .setPathValues('COMPANY_ID', companyId)
    .setPathValues('ID', id)
    .setData({
        userFromEmail:from,
        message,
    });

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
