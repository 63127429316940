import { Content } from '../../../../_metronic/layout/components/content'
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store.ts";
import {useEffect, useState, useRef} from "react";
import {useFormik} from "formik";
import * as Yup from 'yup'
import RCAlerts from "../../../core/utils/swal/RCAlerts.ts";
import IModule from "../../../core/interfaces/IModule.ts";
import EModules from "../../../core/enums/EModules.ts";
import ECommonPermissions from "../../../core/enums/ECommonPermissions.ts";
import sendPackageMessageRequest from "../../../core/api/requests/companies/package/sendPackageMessageRequest.ts";
import sendPackageMessagePublicRequest from '../../../core/api/requests/companies/package/sendPackageMessagePublicRequest.ts';
import RCInput from '../../../utils/RCInput.tsx';
import RCTextArea from "../../../utils/RCTextArea.tsx";
import moment from "moment";
import {savePackage, setPackageValues} from "../../../core/store/slices/package.ts";
import ERoutes from "../../../core/enums/ERoutes.ts";
import {useLocation, useParams, Link} from "react-router-dom";
import { setPackage } from '../../../core/store/slices/package.ts';

export function Messages() {
  const pageName = ERoutes.PACKAGES_MESSAGES
  const params = useParams()
  const location = useLocation()
  const intl = useIntl()
  const dispatch = useDispatch()
  const [isPublic, setIsPublic] = useState<boolean>(false)
  const [fromEmail, setFromEmail] = useState<string>('')
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const pkg = useSelector((state: RootState) => state.package)

  const schema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({id: 'VALIDATIONS.REQUIRED'})),
    message: Yup.string().required()
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      message: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      update(values)
      formik.resetForm();
    }
  })

  const module: IModule = {
    module: EModules.PACKAGES,
    method: ECommonPermissions.UPDATE,
    name: intl.formatMessage({ id: 'MODULE.PACKAGES' }),
    singularName: intl.formatMessage({ id: 'MODULE.PACKAGE' }),
  }

  const update = async (data: any) => {
    if (!pkg.package?.id) return
    if (!data.message) return

    const a = new RCAlerts(module)

    const response = !isPublic ?
      await sendPackageMessageRequest( 
        dispatch,
        pkg?.package.id,
        data.message,
      )
      :
      await sendPackageMessagePublicRequest( 
        dispatch,
        pkg?.package.id,
        params.cid,
        data.message,
        fromEmail
      )

    a.update({
      response,
      successHttpCode: 200,
      onAccept() {
        if (response.data) {
          dispatch(setPackage(response.data));
        }
        //window.location.reload()
      },
      onCancel() {}
    })
  }

  useEffect(() => {
    if(location.pathname.includes('/p/')){
      const email = pkg.package?.recipients?.map(r => r.email)
      if(email && email?.length > 0){
        setFromEmail(email[0] )
      }
      setIsPublic(true)
    }

  },[pkg.package])

  useEffect(() => {
    dispatch(setPackageValues({
      key: pageName,
      data: formik.values,
      loaded: pkg.pageData[pageName]?.loaded || params.id === ERoutes.NEW_PARAMETER,
    }))
  }, [formik.values]);

  useEffect(() => {
    if (pkg.pageData[pageName] && pkg.pageData[pageName].loaded) {
      formik.setValues({
        ...pkg.pageData[pageName].data,
      })
      return
    }

    if (!pkg.package) return

    formik.setValues({
      name: pkg.package.name || '',
      message: '',
    })

    dispatch(setPackageValues({
      key: pageName,
      data: formik.values,
      loaded: true,
    }))
  }, [pkg.package]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [pkg.package?.messages]);


  return (
    <Content>
      <div className='card mb-5 mb-xl-10 h-100'>
        <div id='kt_account_profile_details' className='h-100'>
          <form onSubmit={formik.handleSubmit} noValidate className='form h-100 d-flex flex-column'>
            {pkg?.package?.id ?
              <>
                <div className="card-header" id="kt_chat_messenger_header">
                  <div className="card-title">
                    <div className="d-flex justify-content-center flex-column me-3">
                      <a href="#" className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                        {intl.formatMessage({id: 'PLACEHOLDERS.SUBJECT'})}: {pkg?.package?.name}
                      </a>
                    </div>
                  </div>
                </div>

                <div className='card-body bg-light border-top p-0 flex-grow-1 position-relative'>
                  <div className={`scroll-y me-n5 ${isPublic ? 'h-100 position-absolute' :'h-300px'} px-9 pb-9 w-100`}>
                    {(pkg?.package?.messages || []).map((message: any, index: number) => {
                      const isMine = (!isPublic && message.userEmail === pkg?.package?.owner?.email)
                         ||  (isPublic && message.userEmail === fromEmail) ? true : false;
                      const justifyContent = isMine ? 'end' : 'start';
                      const reverseRow = !isMine ? 'flex-row-reverse' : ''
                      const prevMessage = pkg?.package?.messages ? pkg?.package?.messages[index - 1] : null;
                      const showHeader = 
                        !prevMessage ||
                        prevMessage.userEmail !== message.userEmail || 
                        moment(prevMessage.createdAt).format('DD/MM/YYYY') !== moment(message.createdAt).format('DD/MM/YYYY');
                    

                        return <div className={`d-flex justify-content-${justifyContent} mt-2`} key={message.id}>
                          <div className={`d-flex flex-column align-items-${justifyContent}`}>
                            {showHeader && 
                              <div className={`d-flex ${reverseRow} align-items-center mt-10 mb-4`}>
                                <span className="text-muted fs-7 mx-3">
                                  {moment(message.createdAt).format('DD/MM/YYYY')}
                                </span>
                                <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">
                                  {isMine? intl.formatMessage({id: 'PLACEHOLDERS.YOU'}) : message.userEmail}
                                </a>
                              </div>
                            }
                            <div className={`d-flex ${reverseRow} align-items-center`}>
                              {/* <span className="text-muted fs-7 mx-2">
                                {moment(message.createdAt).format('HH:mm')}
                              </span> */}
                              <div className={`py-3 px-5 rounded ${isMine? 'bg-light-primary' : 'bg-white'} border text-gray-900 mw-lg-400px`}>
                                {message.message}
                              </div>
                            </div>
                          </div>
                        </div>
                    })}
                    <div ref={messagesEndRef} />
                  </div>
                </div>

                <div className="card-footer pt-4" id="kt_chat_messenger_footer">
                  <RCTextArea
                    hideLabel
                    className={'form-control form-control-flush mb-3'}
                    rows={1}
                    fieldsProps={formik.getFieldProps('message')}
                    i18n={'PLACEHOLDERS.MESSAGE'}
                    value={formik.values.message}
                  />

                  <div className="d-flex justify-content-end">
                    <button className="btn btn-primary">Send</button>
                  </div>
                </div>
              </>
              :
              <>
                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      {intl.formatMessage({id: 'PLACEHOLDERS.SUBJECT'})}
                    </label>

                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-12 fv-row'>
                          <RCInput
                            hideLabel
                            fieldsProps={formik.getFieldProps('name')}
                            i18n={'PLACEHOLDERS.SUBJECT'}
                            error={formik.errors.name}
                            value={formik.values.name}
                            touched={formik.touched.name}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className=''>
                        {intl.formatMessage({id: 'PLACEHOLDERS.MESSAGE'})}
                      </span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <RCTextArea
                        hideLabel
                        fieldsProps={formik.getFieldProps('message')}
                        i18n={'PLACEHOLDERS.MESSAGE'}
                        error={formik.errors.message}
                        value={formik.values.message}
                        touched={formik.touched.message}
                      />
                    </div>
                  </div>
                </div>

                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <Link to={ERoutes.PACKAGES}
                    className="btn btn-active-light me-3">
                    {intl.formatMessage({id: 'PLACEHOLDERS.CANCEL'})}
                  </Link>
                  <button type='button' onClick={() => dispatch(savePackage())} className='btn btn-primary'>
                    {intl.formatMessage({id: 'PLACEHOLDERS.SAVE'})}
                  </button>
                </div>
              </>
            }
          </form>
        </div>
      </div>
    </Content>
  )
}
